import logo from '../assets/Yi.png';

const Home = () => {
  return (
    <div className="App">
      <div className='App-body'>
        <div id='theme-poem' className='poem'>
          <div className='poem-english'>
            <div className='poem-title'>No Name</div>
            <div className='poem-author'>Yi Jie</div>
            <p>From one stem grows another,</p>
            <p>Life's sequence unfolds further.</p>
            <p>In nature's dance, a subtle cue,</p>
            <p>One begets two, as old births new.</p>
          </div>
          <div className='poem-chinese'>
            <p>一節又育新一節，物換星移道恆遠。</p>
            <p>二生三啓萬物接，秋葉春花續前緣。</p>
          </div>
        </div>
        <img src={logo} className="App-logo" alt="logo" />
        <p>When the image spins a circle, sixty seconds have elapsed.</p>
      </div >
    </div>
  );
}

export default Home;