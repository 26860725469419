import { HashRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Thoughts from './pages/Thoughts';
import PageNotFound from './pages/PageNotFound';

const Header = () => (
  <header className="App-header">
    <p>
      一节
    </p>
  </header>
);

const Footer = () => (
  <footer className="App-footer">
    <p>© 2024 Yi Jie. All Rights Reserved.</p>
  </footer>
);

// const Navbar = () => (
//   <nav>
//     <ul>
//       <li><Link to="/">Home</Link></li>
//       <li><Link to="/thoughts">Thoughts</Link></li>
//     </ul>
//   </nav>
// );

// Main() is just an indirection as useLocation needs to be consumed by a component that is a descendant of a <Router> component.
const Main = () => {
  const location = useLocation();

  return (
    <>
      {location.pathname !== '/404' && <Header />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/t/:id" element={<Thoughts />} />
        <Route path="/404" element={<PageNotFound />} />
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
      {location.pathname !== '/404' && <Footer />}
    </>
  );
};

const App = () => (
  <Router>
    <Main />
  </Router>
);

export default App;