import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

const appInsights = new ApplicationInsights({
  config: {
    connectionString: 'InstrumentationKey=53a8e97e-fe57-411a-871c-6db2579a5877;IngestionEndpoint=https://westus3-1.in.applicationinsights.azure.com/;ApplicationId=6ead2c97-5b7e-44b4-ab3a-ea1c7ceb88a2',
    autoTrackPageVisitTime: true,
    enableAutoRouteTracking: true
  }
});

appInsights.loadAppInsights();

appInsights.addTelemetryInitializer((telemetryItem) => {
  var baseData = telemetryItem.data?.baseData;
  if (baseData) {
    // If the telemetry item type is a request, add the referrer information
    if (baseData.url && document.referrer) {
      baseData.properties = baseData.properties || {};
      baseData.properties.referrer = document.referrer;
    }
  }
});

appInsights.trackPageView(); // Manually call trackPageView to log the page view
