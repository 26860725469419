import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ReactMarkdown from 'react-markdown';

function Thoughts() {
  const { id } = useParams<{ id: string }>();
  const [thoughts, setThoughts] = useState<string>('Something went wrong. Please try again later.');
  const navigate = useNavigate();

  useEffect(() => {
    import(`../thoughts/${id}.md`).then(res => {
      fetch(res.default)
        .then(response => response.text())
        .then(text => setThoughts(text))
        .catch(error => {
          console.error(error);
        });
    }).catch(error => {
      if (error.code === 'MODULE_NOT_FOUND') {
        navigate('/404', { replace: true });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div className="App">
      <div className='App-body'>
        <div className="App-body-thoughts">
          <ReactMarkdown children={thoughts} />
        </div>
      </div>
    </div>
  );
}

export default Thoughts;