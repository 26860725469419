const PageNotFound = () => (
  <div>
    <h1>404 - Page Not Found</h1>
    <p style={{ fontSize: '6em' }}>😢</p>
    <p style={{ fontSize: '1.5em' }}>Sorry, you can't get there from here. The page you are looking for could not be found.
    </p>
    <a href="/">Go to the home page</a>
  </div>
);

export default PageNotFound;